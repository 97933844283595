import React from "react";
import SEO from "../../components/seo";
import H1 from "../../components/h1";
import Par from "../../components/Par";

const FeesAndCharges = () => {
	return (
		<div>
			<SEO
				title="Fees & Charges"
				keywords={[
					`Fees`,
					`Charges`,
					`Gastroenterologist`,
					`Hepatologist`,
					`Dr Frank Brennan`,
					`Dr Spiro Raftopoulos`,
					`Dr Ian Yusoff`,
					`Dr Dev Segarajasingam`,
					`Dr Sam Rao`,
					`Dr Duy Pham`,
					`Dr Claire Gordon`,
				]}
				description="Fees and charges at Advanced GI"
			/>
			<H1 text="Fees and Charges" />
			<Par text="It is the policy of this practice to encourage and support the use of private insurance and therefore nearly all inpatient procedures are billed directly to the insurer. All the anaesthetists currently retained by this practice are no gap providers. There may be a gap where a procedure is expected to be long and complicated and is being undertaken as an alternative to surgery; in this situation the gap involved and the reasons for it will be conveyed to the patient prior to the procedure being undertaken." />
			<Par
				text="				The gap for consultations may vary from $50.00 to $250.00 depending on the length and complexity of the consultation. Patients referred for a second opinion or by other specialists for more expert opinion usually involve more time. An estimate of any gap can be provided when the referral is received by the practice and is reviewed by the relevant specialist. EFTPOS facilities are available and payment on the day is encouraged. A valid referral is the patients responsibility and is required if you wish to claim a medicare rebate.
"
			/>
			<Par
				text="				For patients with overseas insurance or uninsured patients who wish to go privately the fees charged are at the level recommended by the Australian Medical Association.
"
			/>
		</div>
	);
};

export default FeesAndCharges;
